$font-stack: -apple-system, BlinkMacSystemFont,Roboto,'Open Sans','Helvetica Neue',sans-serif;
$nearWhite-color: #FDFDF4;

$breakpoints: (
        'xs': ('(min-width: 576px)'),
        'sm': ('(min-width: 768px)'),
        'md': ('(min-width: 992px)'),
        'lg': ('(min-width: 1200px)'),
        'xl': ('(min-width: 1600px)'),
);


@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @error 'Не указано значение для `#{$breakpoint}`';
  }
}


//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
//@import "~bootstrap/scss/input-group";
//@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
//@import "~bootstrap/scss/utilities";
//@import "~bootstrap/scss/print";

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Roboto-Regular.ttf);
}

html {
  font-size: 20px;
}

body {
  font-family: $font-stack;
  color: #000;
  //#logo {
  //  width: 10rem;
  //}
  //.container {
  //  img {
  //    width: 20rem;
  //  }
  //}
}

header {
  padding-bottom: 1rem;
}

.row > div {
  position: relative;
}

//a {
//  &.anchor {
//  }
//}

.navbar {
  padding: 0;
}

.navbar-collapse {
  justify-content: flex-end;
}


.logo {
  display: flex;
  align-items: center;
}

.logo--title {
  padding-bottom: 2rem;
}

.logo--subtitle {
  padding-bottom: 1rem;
}

.logo__img {
  height: 80px;
  //padding: 0 2px;
}
.logo__img--subtitle {
  height: 50px;
}
.logo__desc {
  line-height: 2.5rem;
  font-size: 2rem;
  font-weight: 700;
}
.logo__desc--small {
  font-size: 1.5rem;
}

.logo__desc--subtitle {
  padding-left: 0.5rem;
  //text-transform: capitalize;
}



.mainBlockHR {
  border-top: 3px solid #000;
}


// mainPage__newsList
.mainPage__newsList {
  margin-bottom: 4rem;
  @include respond-to('sm') {
    margin-bottom: 0;
  }
}


//RECORDS

.records {
  .controls {
    &.none {
      display: none;
    }
  }
  .output {

    .loader {
      text-align: center;
      padding: 20px;
      & > div {
        margin: 0  0 10px;
      }

      &.ulLoader {
        margin-left: -40px;
      }
    }
  }
}

.record {
  margin-bottom: 1.8rem;

  a, a:hover {
    color: unset;


  }

  a.usflLinkWRP {
    &:hover {
      text-decoration: none;
      .record__title span, .record__description {
        text-decoration: underline;
      }
    }
  }

  &.record--collapse {

    //transition: height 0.3s ease-in-out;

    .collapse, .collapsing {
      display: block;
      min-height: 100px;
      visibility: visible;
    }

    .collapse {
      transition: height 0.3s ease-in-out;
      //height: 200px;
      min-height: 100px;
      max-height: 250px;
      overflow: hidden;
      &.show {
        max-height: unset;
      }

      &.active {
        max-height: unset;
        //height: 500px;
        //height: auto;

      }
    }
  }
}

.record__difficulty {
  color: #888;
  font-size: 0.8rem;
}

.record__title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 6px;

  .anchor {
    opacity: 0;
    pointer-events: none;
  }

  sup {
    border: 1px solid #aaa;
    padding: 0 2px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 16px;
    pointer-events: none;
    margin-left: 2px;
    &.colored {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }
  }

  &:hover {
    .anchor {
      opacity: 0.6;
      pointer-events: unset;

      &:hover {
        opacity: 1;
      }
    }

  }

}

.card {
  .anchor {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    .anchor {
      opacity: 0.6;
      pointer-events: unset;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.record__description {
  color: #565656;
  padding-bottom: 0.5rem;
}

.record__author {
  font-weight: 500;
}

.record__status {
  font-style: italic;
}

.record__tags {
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
}
.record__tags > div {
  padding: 5px;
  margin-right: 10px;
  font-size: 14px;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.categoryList {
  padding: 0;
  margin: 0 0 20px;
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
}
.categoryList li {
  padding: 10px 20px;
  margin: 0 10px 20px 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px #555;
  list-style: none;
  cursor: pointer;
}
.categoryList li:hover {
  box-shadow: 0px 1px 4px 0px #333;
  transform: translate(0px, 1px);
  background-color: $nearWhite-color;

}

.categoryList li.active {
  background-color: #ccc;
}


.newsList {

  .record {

    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: 2rem;
    }
    .miniCollapseWrp {

      .collapse {
        position: relative;
        .record__description {
          margin-bottom: 20px;
        }

        button {
          //content: "Показать полностью";
          display: block;
          width: 100%;
          height: 130px;
          padding-top: 90px;
          position: absolute;
          top: 120px;
          border: none;
          outline: none;
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.1+0,1+61 */
          background: -moz-linear-gradient(top,  rgba(255,255,255,0.1) 0%, rgba(255,255,255,1) 61%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,1) 61%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,1) 61%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1affffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */



          .toHide {
            display: none;
          }
        }

        &.active {

          button {
            position: static;
            top: unset;
            bottom: 0;
            height: 40px;
            padding-top: 0px;
            background-color: #f5f5f5;
            .toHide {
              display: inline;
            }
            .toShow{
              display: none;
            }
          }
        }
      }
    }
  }

}

// Info Page

.infoPage {
  .row {
    margin-bottom: 40px;
  }
}

//ТРЭНДЫ

.trends {
}

.trend {
  margin-bottom: 2rem;
}

.trend__difficulty {
  color: #888;
  font-size: 0.8rem;
}

.trend__title {
  font-size: 1.2rem;
}

.trend__description {
  color: #565656;
  padding-bottom: 0.5rem;
}
.trend__author {
  font-weight: 500;
}

.trend__status {
  font-style: italic;
}

// USFL LIKS


// Rules
.rulesList {}
.rulesList li b {
  //font-weight: 500;
}

// CODEWARS PAGE
.codewarsPage .row {
  margin-bottom: 2.5rem;
  .logo--title {
    padding-bottom: 1.5rem;
  }
  img.codewarsManual {
    margin-bottom: 1rem;
  }
}

// MANAGER
.mainManagerMenu {
  margin-bottom: 20px;
}

.mainManagerMenu > a {
  text-transform: uppercase;
}

.innerMenu {
  margin-bottom: 20px;
}

.manageForm {
  margin-bottom: 20px;
}

.manageForm form .form-group.row {
  margin-right: 0;
}
.manageForm .schemaBtn {
  margin-bottom: 10px;
}


.manageForm__title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.manageForm textarea {
  min-width: 260px;
  padding: 0 10px 0 4px;
}

.manageForm .card {
  margin-bottom: 20px;
}

.manageForm .selectedTags {
  display: flex;
  height: auto;
  flex-wrap: wrap;
  padding-bottom: 0;
}


.manageForm .search_result {
  display: flex;
  flex-wrap: wrap;
  //justify-content: ;
  padding-top: 10px;
}

.manageForm .resultItem {
  width: auto;
  border: 1px solid #aaa;
  color: #555;
  margin: 0px 10px 5px 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.manageForm .search_result .resultItem {
  min-width: 60px;
  height: 60px;
  margin: 0px 20px 10px 0;
  padding: 10px;
  border-radius: 6px;
}

.manageForm .search_result .resultItem:hover {
  background-color: #eee;
  color: #444;
  top: 1px;
  left: 1px;
}

.manageForm .search_result .resultItem.selected {
  background-color: #ddd;
}

.manageForm .selectedTags .searchTags {
  border: none;
  margin-bottom: 5px;
  max-width: 300px;
  min-width: 100px;
  flex-grow: 1;
  flex-basis: 100px;
  min-height: 36px;
}


.manageForm .selectedTags {
  display: flex;
  flex-wrap: wrap;
}


.manageForm .selectedTags .selectedTag {
  font-size: 16px;
  margin: 0 5px 5px 0;
  //padding: 0 5px;
  height: 36px;
}

.manageForm .selectedTags .selectedTag:hover {
  background-color: #fefefe;
  color: #444;
  top: 1px;
}


.manageForm .selectedTags .selectedTag::after{
  content: "×";
  font-weight: bold;
  font-size: 16px;
  width: 25px;
  text-align: center;
}

.manageForm .selectedTags .selectedTag:hover::after{
  font-size: 22px;
}

.manageForm .modal-footer {
  padding: 0;
  border: 0;
}


.table-responsive {

}

.table-responsive button{
  text-align: center;
}


footer {
  padding-top: 50px;
  text-align: center;
}

// LOADER
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

